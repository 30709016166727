import counterReducer from './counterReducer'
import toggleReducer from './toggleReducer'
import { combineReducers } from 'redux'

export default combineReducers({
  count: counterReducer,
  toggle: toggleReducer,
  on: toggleReducer,
  off: toggleReducer,
})
