import { TOGGLE } from '../actions'
import { ON } from '../actions'
import { OFF } from '../actions'

const toggleReducer = (state = false, action) => {
  switch (action.type) {
    case TOGGLE:
      return !state
    case ON:
      return true
    case OFF:
      return false
    default:
      return state
  }
}
export default toggleReducer
