export const INCREMENT = 'INCREMENT'
export const DECREMENT = 'DECREMENT'
export const TOGGLE = 'TOGGLE'
export const ON = 'ON'
export const OFF = 'OFF'

export const incrementCount = () => ({
  type: INCREMENT,
})

export const decrementCount = () => ({
  type: DECREMENT,
})

export const toggleNewsletter = () => ({
  type: TOGGLE,
})

export const onNewsletter = () => ({
  type: ON,
})
export const offNewsletter = () => ({
  type: OFF,
})
