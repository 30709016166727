module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-76818033-1","exclude":["/admin/**"],"head":false,"anonymize":false,"respectDNT":false,"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"commonmark":true,"pedantic":false,"defaultLayouts":{"default":"/opt/build/repo/src/page-templates/cms-entry.template.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1440,"linkImagesToOriginal":false,"backgroundColor":"transparent","disableBgImageOnAlpha":true}},"gatsby-remark-line-breaks","gatsby-remark-component","gatsby-remark-smartypants","gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin: 32px 0"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
